import React, { Component } from "react";
import { Card, Col, Row, Typography } from "antd";
import { } from "@ant-design/icons";
import Echart from "../chart/Echart";
import LeadsService from "../../api/services/leads.service";

import userpng from "../images/user.png";
import postscribe from "postscribe";


const { Title } = Typography;
export default class Home extends Component {
  componentDidMount = () => {
    postscribe(
      "#globe",
      `
      <script src="./js/threejs.js"></script>
      <script src="./js/orbit-controls.js"></script>
      <script src="./js/globe.js"></script>
    `
    );
    this.getDashboard();
  };
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      placement: "left",
      sidebarColor: "#1890ff",
      sidenavType: "transparent",
      fixed: false,

      //count
      leads: 0,
      projects: 0,
      users: 0,
      visited: 0,
      sold: 0,
      apiResponse: [],
      monthlyLeads: []
    };
  }

  getDashboard() {
    LeadsService.getDashboard()
      .then(response => {
        console.log("Dashboard data");
        console.log(response.data.data);
        this.setState({
          leads: response.data.data.leads,
          projects: response.data.data.projects,
          users: response.data.data.users,
          visited: response.data.data.visited,
          sold: response.data.data.sold,
          apiResponse: response.data.data.performance,
          monthlyLeads: response.data.data.monthlyleads
        });
        // this.state.leads = response.data.leads;
        // this.state.users = response.data.users;
        // this.state.visited = response.data.visited;
        // this.state.sold = response.data.sold;
      })
      .catch(e => {
        console.log(e);
      });
  };

  handleSidebarColor = (color) => {
    this.setState({ sidebarColor: color });
  };

  handleSidenavType = (type) => {
    this.setState({ sidenavType: type });
  };

  handleFixedNavbar = (type) => {
    this.setState({ fixed: type });
  };
  OpenDrawer = () => {
    // /alert("hello");
    this.setState({
      visible: !this.state.visible,
    });
  };

  render() {
    const profile = [
      <svg
        width="45"
        height="60"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        key={0}
      >
        <path
          d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
          fill="#fff"
        ></path>
        <path
          d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z"
          fill="#fff"
        ></path>
        <path
          d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z"
          fill="#fff"
        ></path>
        <path
          d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z"
          fill="#fff"
        ></path>
      </svg>,
    ];

    const leads = [
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="60"
        viewBox="0 0 24 24"
        fill="none"
        key={0}
      >
        <path
          d="M12 1C10.3431 1 9 2.34315 9 4C9 5.65685 10.3431 7 12 7C13.6569 7 15 5.65685 15 4C15 2.34315 13.6569 1 12 1ZM12 9C8.13401 9 5 12.134 5 16H19C19 12.134 15.866 9 12 9ZM12 21C9.23858 21 7 18.7614 7 16H17C17 18.7614 14.7614 21 12 21Z"
          fill="#fff" // Set your desired color for the leads icon
        />
      </svg>,
    ];

    const leadsIcon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="60"
        viewBox="0 0 24 24"
        fill="none"
        key={0}
      >
        <path
          d="M12 1C10.3431 1 9 2.34315 9 4C9 5.65685 10.3431 7 12 7C13.6569 7 15 5.65685 15 4C15 2.34315 13.6569 1 12 1ZM12 9C8.13401 9 5 12.134 5 16H19C19 12.134 15.866 9 12 9ZM12 21C9.23858 21 7 18.7614 7 16H17C17 18.7614 14.7614 21 12 21Z"
          fill="#fff" // Set your desired color for the leads icon
        />

        {/* Add more circles to represent additional leads */}
        <circle cx="10" cy="12" r="1" fill="#1890FF" />
        <circle cx="12" cy="12" r="1" fill="#1890FF" />
        <circle cx="14" cy="12" r="1" fill="#1890FF" />
      </svg>
    );

    const projectsIcon = (

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="60"
        viewBox="0 0 45 60"
        fill="none"
        key={0}
      >
        {/* Building 1 */}
        <rect x="2" y="20" width="8" height="40" fill="#fff" />
        <rect x="4" y="15" width="4" height="5" fill="#1890FF" />
        {/* Windows in Building 1 */}
        <circle cx="3.5" cy="23" r="1" fill="#1890FF" />
        <circle cx="5.5" cy="23" r="1" fill="#1890FF" />
        <circle cx="7.5" cy="23" r="1" fill="#1890FF" />
        <circle cx="3.5" cy="26" r="1" fill="#1890FF" />
        <circle cx="5.5" cy="26" r="1" fill="#1890FF" />
        <circle cx="7.5" cy="26" r="1" fill="#1890FF" />
        <circle cx="3.5" cy="29" r="1" fill="#1890FF" />
        <circle cx="5.5" cy="29" r="1" fill="#1890FF" />
        <circle cx="7.5" cy="29" r="1" fill="#1890FF" />
        {/* Building 2 */}
        <rect x="12" y="25" width="8" height="35" fill="#fff" />
        <rect x="14" y="20" width="4" height="5" fill="#1890FF" />
        {/* Windows in Building 2 */}
        <circle cx="13.5" cy="28" r="1" fill="#1890FF" />
        <circle cx="15.5" cy="28" r="1" fill="#1890FF" />
        <circle cx="17.5" cy="28" r="1" fill="#1890FF" />
        <circle cx="13.5" cy="31" r="1" fill="#1890FF" />
        <circle cx="15.5" cy="31" r="1" fill="#1890FF" />
        <circle cx="17.5" cy="31" r="1" fill="#1890FF" />
        {/* Building 3 */}
        <rect x="22" y="18" width="8" height="37" fill="#fff" />
        <rect x="24" y="15" width="4" height="3" fill="#1890FF" />
        {/* Windows in Building 3 */}
        <circle cx="23.5" cy="21" r="1" fill="#1890FF" />
        <circle cx="25.5" cy="21" r="1" fill="#1890FF" />
        <circle cx="27.5" cy="21" r="1" fill="#1890FF" />
        <circle cx="23.5" cy="24" r="1" fill="#1890FF" />
        <circle cx="25.5" cy="24" r="1" fill="#1890FF" />
        <circle cx="27.5" cy="24" r="1" fill="#1890FF" />
        {/* Building 4 */}
        <rect x="32" y="15" width="8" height="40" fill="#fff" />
        <rect x="34" y="12" width="4" height="3" fill="#1890FF" />
        {/* Windows in Building 4 */}
        <circle cx="33.5" cy="18" r="1" fill="#1890FF" />
        <circle cx="35.5" cy="18" r="1" fill="#1890FF" />
        <circle cx="37.5" cy="18" r="1" fill="#1890FF" />
        <circle cx="33.5" cy="21" r="1" fill="#1890FF" />
        <circle cx="35.5" cy="21" r="1" fill="#1890FF" />
        <circle cx="37.5" cy="21" r="1" fill="#1890FF" />
      </svg>
    );

    const sold = [
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="60"
        viewBox="0 0 24 24"
        fill="none"
        key={0}
      >
        <path
          d="M12 1C10.3431 1 9 2.34315 9 4C9 5.65685 10.3431 7 12 7C13.6569 7 15 5.65685 15 4C15 2.34315 13.6569 1 12 1ZM12 9C8.13401 9 5 12.134 5 16H19C19 12.134 15.866 9 12 9ZM12 18C9.23858 18 7 15.7614 7 13H17C17 15.7614 14.7614 18 12 18Z"
          fill="#fff" // Set your desired color for the sold icon
        />
      </svg>,
    ];

    const count = [
      {
        today: "Projects",
        title: this.state.projects,
        persent: "",
        icon: projectsIcon,
        bnb: "bnb2",
      },
      {
        today: "Leads",
        title: this.state.leads,
        persent: "",
        icon: leadsIcon,
        bnb: "bnb2",
      },
      {
        today: "Users",
        title: this.state.users,
        persent: "",
        icon: profile,
        bnb: "bnb2",
      },
      {
        today: "Visited",
        title: this.state.visited,
        persent: "",
        icon: leads,
        bnb: "redtext",
      },
      {
        today: "Sold",
        title: this.state.sold,
        persent: "",
        icon: sold,
        bnb: "bnb2",
      },
    ];

    const list = this.state.apiResponse.map((user) => ({
      img: userpng,
      Title: (
        <>
          <p className="text-xs font-weight-bold mb-0">User:</p>
          <h6>{user.name}</h6>
        </>
      ),
      bud: (
        <>
          <p className="text-xs font-weight-bold mb-0">Sold</p>
          <h6>{user.soldCount}</h6>
        </>
      ),
      progress: (
        <>
          <p className="text-xs font-weight-bold mb-0">Site Visits</p>
          <h6>{user.siteVisits}</h6>
        </>
      ),
      member: (
        <>
          <p className="text-xs font-weight-bold mb-0">C.Ratio:</p>
          <h6>{user.conversionRatio}%</h6>
        </>
      ),
    }));

    // Display the 'list' array
    //console.log(list);

    // const list = [
    //   {
    //     img: userpng,
    //     Title: (
    //       <>
    //         <p className="text-xs font-weight-bold mb-0">User:</p>
    //         <h6>User Name</h6>
    //       </>
    //     ),
    //     bud: (
    //       <>
    //         <p className="text-xs font-weight-bold mb-0">Sold</p>
    //         <h6>02</h6>
    //       </>
    //     ),
    //     progress: (
    //       <>
    //         <p className="text-xs font-weight-bold mb-0">Site Visits</p>
    //         <h6>100</h6>
    //       </>
    //     ),
    //     member: (
    //       <>
    //         <p className="text-xs font-weight-bold mb-0">C.Ratio:</p>
    //         <h6>9%</h6>
    //       </>
    //     ),
    //   },
    //   {
    //     img: userpng,
    //     Title: (
    //       <>
    //         <p className="text-xs font-weight-bold mb-0">User:</p>
    //         <h6>User Two</h6>
    //       </>
    //     ),
    //     bud: (
    //       <>
    //         <p className="text-xs font-weight-bold mb-0">Sold</p>
    //         <h6>02</h6>
    //       </>
    //     ),
    //     progress: (
    //       <>
    //         <p className="text-xs font-weight-bold mb-0">Site Visits</p>
    //         <h6>45</h6>
    //       </>
    //     ),
    //     member: (
    //       <>
    //         <p className="text-xs font-weight-bold mb-0">C.Ratio:</p>
    //         <h6>8%</h6>
    //       </>
    //     ),
    //   },

    //   {
    //     img: userpng,
    //     Title: (
    //       <>
    //         <p className="text-xs font-weight-bold mb-0">User:</p>
    //         <h6>User Four</h6>
    //       </>
    //     ),
    //     bud: (
    //       <>
    //         <p className="text-xs font-weight-bold mb-0">Sold</p>
    //         <h6>01</h6>
    //       </>
    //     ),
    //     progress: (
    //       <>
    //         <p className="text-xs font-weight-bold mb-0">Site Visits</p>
    //         <h6>50</h6>
    //       </>
    //     ),
    //     member: (
    //       <>
    //         <p className="text-xs font-weight-bold mb-0">C.Ratio:</p>
    //         <h6>4%</h6>
    //       </>
    //     ),
    //   },

    //   {
    //     img: userpng,
    //     Title: (
    //       <>
    //         <p className="text-xs font-weight-bold mb-0">User:</p>
    //         <h6>User Last</h6>
    //       </>
    //     ),
    //     bud: (
    //       <>
    //         <p className="text-xs font-weight-bold mb-0">Sold</p>
    //         <h6>01</h6>
    //       </>
    //     ),
    //     progress: (
    //       <>
    //         <p className="text-xs font-weight-bold mb-0">Site Visits</p>
    //         <h6>85</h6>
    //       </>
    //     ),
    //     member: (
    //       <>
    //         <p className="text-xs font-weight-bold mb-0">C.Ratio:</p>
    //         <h6>5%</h6>
    //       </>
    //     ),
    //   },
    // ];

    return (
      <>
        <div className="layout-content dashboard-div">
          <Row gutter={[24, 0]}>
            <Col lg={12} className="zindex">
              <h2 className="p-15 h4">DashPanel</h2>
              <Row className="rowgap-vbox" gutter={[24, 0]}>
                {count.map((c, index) => (
                  <Col key={index} xs={24} sm={24} lg={8} className="mb-24">
                    <Card bordered={false} className="criclebox ant-col-cust">
                      <div className="number">
                        <Row align="middle" gutter={[0]}>
                          <Col xs={18}>
                            <span>{c.today}</span>
                            <Title level={3}>
                              {c.title}{" "}
                              <small className={c.bnb}>{c.persent}</small>
                            </Title>
                          </Col>
                          <Col xs={6}>
                            <div className="icon-box icon-box-cust">{c.icon}</div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Card
                bordered={false}
                bodyStyle={{ padding: "0px" }}
                className="criclebox cardbody mb-24 header-solid"
                title={[<h6 key={0}>Team Performance</h6>]}
              >
                <div className="ant-list-box table-responsive">
                  <table className="width-100">
                    <tbody>
                      {list.map((d, index) => (
                        <tr key={index}>
                          <td>
                            <img
                              src={d.img}
                              alt=""
                              className="avatar-sm mr-10"
                            />
                          </td>
                          <td>{d.Title}</td>
                          <td>{d.bud} </td>
                          <td>{d.progress}</td>
                          <td>{d.member}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card>
            </Col>

            {/* <img src={globe} alt="" /> */}
            <div id="globe" className="globeContainer peekaboo">
              <canvas
                width="700"
                height="655"
                className="w-lg-100 h-lg-100 w-75 h-75 me-lg-0 me-n10 mt-lg-5"
              ></canvas>
            </div>
          </Row>

          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} lg={24} xl={24} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                <Echart monthlyLeads={this.state.monthlyLeads} />
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
