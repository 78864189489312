import {
  CodeFilled,
  AppstoreFilled,
  DollarCircleFilled,
  LockFilled,
  ReadFilled,
  RocketFilled,
  SwitcherFilled,
  DashboardFilled,
  HomeFilled,
  UserOutlined,
  UsergroupAddOutlined,
  UserSwitchOutlined,
  ProfileOutlined
} from "@ant-design/icons";
const menuItems = {
  items: [
    {
      id: "null0",
      title: "",
      type: "group",
      children: [
        {
          id: "das",
          title: <span className="label">Home</span>,
          type: "item",
          icon: (isSelected, color) => (
            <HomeFilled
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          url: "/dashboard",
        },
        {
          id: "leads",
          title: <span className="label">Leads</span>,
          type: "collapse",
          icon: (isSelected, color) => (
            <AppstoreFilled
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          children: [
            {
              id: "add-lead",
              title: "Add Lead",
              type: "item",
              url: "/leads/newlead",
            },
            {
              id: "list-lead",
              title: "Leads List",
              type: "item",
              url: "/leads/leadslist",
            },
            {
              id: "dropout-lead",
              title: "Dropout Leads",
              type: "item",
              url: "/leads/dropoutleads",
            },
          ],
        },
        {
          id: "leadstransfer",
          title: <span className="label">Leads Transfer</span>,
          type: "collapse",
          icon: (isSelected, color) => (
            <UserSwitchOutlined
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          children: [
            {
              id: "internal-request",
              title: "Internal Request",
              type: "item",
              url: "/leadtransfer/internalrequest",
            },
          ],
        },
        {
          id: "projects",
          title: <span className="label">Projects</span>,
          type: "collapse",
          icon: (isSelected, color) => (
            <ProfileOutlined
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          children: [
            {
              id: "assigned-projects",
              title: "Assigned Projects",
              type: "item",
              url: "/projects/assigned",
            },
            {
              id: "unassigned-projects",
              title: "Un-Assigned Projects",
              type: "item",
              url: "/projects/unassigned",
            },
          ],
        },
        {
          id: "addusers",
          title: <span className="label">Add Users</span>,
          type: "collapse",
          icon: (isSelected, color) => (
            <UsergroupAddOutlined
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          children: [
            {
              id: "sales-manager",
              title: "Sales Manager",
              type: "item",
              url: "/user/manager",
            },
            {
              id: "executive",
              title: "Executive",
              type: "item",
              url: "/user/executive",
            },
          ],
        },
        {
          id: "users-list",
          title: "Users List",
          type: "item",
          icon: (isSelected, color) => (
            <UserOutlined
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          url: "/user/userslist",
        }
      ],
    },
  ],
};

export default menuItems;
