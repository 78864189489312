/* eslint-disable import/no-anonymous-default-export */
import React, { Component } from "react";
import { Row, Col, Table, Card, Select, Button, Modal } from "antd";
import {
  SearchOutlined,
} from "@ant-design/icons";
import projectServices from "../../api/services/project.services";
import userServices from "../../api/services/user.services";

const { Option } = Select;
let selectedUsers = new Array();

const suffix = (
  <SearchOutlined
    style={{
      fontSize: 16,
      color: "#1890ff",
    }}
  />
);

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    selectedUsers = selectedRowKeys;
    console.log(selectedUsers);
  },
};

const data = [

];

export default class extends Component {
  constructor(props) {
    super(props);

    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.state.project = "";
  }
  state = {
    selectedRowKeys: [], // Check here to configure the default column
  };
  componentDidMount() {
    this.myParams();
    this.getUsersList();
  }
  myParams() {
    let location = this.props.location;
    let params = new URLSearchParams(location.search);
    let encodedproject = params.get('project');
    // console.log(atob(project));
    this.state.project = atob(encodedproject);

  }
  getUsersList() {
    userServices.getUnassignedUsers(this.state.project)
      .then(response => {
        this.data = response.data.data;
        this.setState({
          filteredData: response.data.data
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  redirectPage = () => {
    this.props.history.push('/projects/assigned');
  }

  onClickSubmit(e) {
    console.log(selectedUsers);
    if (selectedUsers.length == 0) {
      Modal.error({
        content: 'Please select user to assign project',
      });
    } else {
      var submitdata = {
        project: this.state.project,
        users: selectedUsers
      };
      console.log(submitdata);
      projectServices.assignSelectedUsers(submitdata)
        .then(response => {
          Modal.success({
            content: "Users assigned to Project Successfully",
          });
          this.redirectPage();
        })
        .catch(e => {
          console.log(e);
        });
    }
  }

  state = {
    filteredInfo: null,
    sortedInfo: null,
    totalPage: 5,
    totalPage1: 5,
    filteredData: [

    ],
    project: null,
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      totalPage: pagination,
    });
  };

  handleFilter = (e) => {
    let newFiltered = [...this.state.filteredData];
    newFiltered = newFiltered.filter(
      (data) =>
        data.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.mobile.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.source.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.project.toString().includes(e.target.value.toLowerCase()) ||
        data.created.toString().includes(e.target.value.toLowerCase()) ||
        data.createdby.toString().includes(e.target.value.toLowerCase())
    );
    this.setState({ filteredData: newFiltered });
  };

  handleTotalPageChange = (val) => {
    this.setState({ totalPage: val });
  };
  handleTotalPageChange1 = (val) => {
    this.setState({ totalPage1: val });
  };
  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => a.date - b.date,
      },
      {
        title: "Username",
        dataIndex: "username",
        sorter: (a, b) => a.customer - b.customer,
      },
      {
        title: "Role",
        dataIndex: "role",
        sorter: (a, b) => a.product - b.product,
      },
      {
        title: "Email",
        dataIndex: "email",
        sorter: (a, b) => a.revenue - b.revenue,
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        sorter: (a, b) => a.revenue - b.revenue,
      },
    ];

    function onChange(pagination, filters, sorter, extra) {
      console.log("params", pagination, filters, sorter, extra);
    }

    return (
      <>
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Card
              bodyStyle={{ padding: "16px 0px 0px" }}
              bordered={false}
              className="header-solid mb-24"
              title={[<h5 className="font-semibold">Select User to Assign</h5>]}
            >
              <Table
                tableLayout="auto"
                scroll={{ x: 500 }}
                columns={columns}
                // dataSource={data}
                dataSource={this.state.filteredData}
                onChange={onChange}
                rowSelection={{ ...rowSelection }}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col className="md-24 text-right" span={24}>
            <Button
              onClick={this.onClickSubmit}
              type="primary"
              className="px-25"
            >
              SUBMIT
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}
