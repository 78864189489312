import "antd/dist/antd.css";
import "antd-button-color/dist/css/style.css";
import "./App.css";
import "./responsive.css";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

import SignUp from "./component/pages/SignUp";

import Main from "./component/layout/main";
import Pricing from "./component/pages/Pricing";
import Cover from "./component/authentication/signup/Cover";
import Illustration from "./component/authentication/signup/Illustration";

function App() {
  const history = useHistory();
  const authToken = window.localStorage.getItem('Authorization');
  if (!authToken) {
    // Redirect to the login page
    history.push('/sign-in');
  }
  return (
    <div className="App">
      <Switch>
        <Route path="/sign-up" exact component={SignUp} />
        <Route path="/authentication/sign-up/basic" exact component={SignUp} />
        <Route path="/authentication/sign-up/cover" exact component={Cover} />
        <Route
          path="/authentication/sign-up/illustration"
          exact
          component={Illustration}
        />
        <Route path="/sign-in" exact component={Illustration} />
        <Route path="/pages/pricing" exact component={Pricing} />

        <Route path="/:id" component={Main} />

        <Redirect
          to={{
            pathname: "/dashboard",
          }}
        />
      </Switch>
    </div>
  );
}

export default App;
