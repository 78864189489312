import React, { Component } from "react";
import { Timeline, Row, Col, Card, Descriptions, Button } from "antd";
import leadsService from "../../api/services/leads.service";
import { daysBetweenDates } from "../../utils/date-calculator";

export default class TimeLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      mobile: "",
      project: "",
      source: "",
      createdby: "",
      dropoutdate: "",
      dropoutexecutive: "",
      dropoutdays: "",
      data: {}
    };
  }

  componentDidMount() {
    this.getLeadDetails();
    this.getLeadsHistory();
  }

  getLeadDetails() {
    let location = this.props.location;
    let params = new URLSearchParams(location.search);
    let lead = params.get('lead');
    let leadId = atob(lead);
    console.log(leadId);

    leadsService.getDropoutLeadDetails(leadId)
      .then(response => {
        const today = new Date();
        const dodays = daysBetweenDates(today, response.data.data.dropoutdate);
        this.setState({
          name: response.data.data.name
        });
        this.setState({
          email: response.data.data.email
        });
        this.setState({
          mobile: response.data.data.mobile
        });
        this.setState({
          source: response.data.data.source
        });
        this.setState({
          project: response.data.data.project
        });
        this.setState({
          createdby: response.data.data.createdby
        });
        this.setState({
          dropoutdate: response.data.data.dropoutdate
        });
        this.setState({
          dropoutexecutive: response.data.data.dropoutexecutive
        });
        this.setState({
          dropoutdays: Math.abs(Math.floor(dodays)) + " days"
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  getLeadsHistory() {
    let location = this.props.location;
    let params = new URLSearchParams(location.search);
    let lead = params.get('lead');
    let leadId = atob(lead);
    console.log(leadId);
    leadsService.getLeadHistory(leadId)
      .then(response => {
        this.setState({
          data: response.data.data
        });
        console.log(this.state.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    const timeline = [0, { cls: "" }];
    return (
      <>
        <Row gutter={[24, 0]}>
          <Col className="mb-24 text-right" span={24}>
            <Button type="primary" className="ml-15">Restore</Button>
            <Button type="success" className="ml-15">Internal Transfer</Button>
            <Button type="danger" className="ml-15">Delete</Button>
          </Col>

          <Col span={24} md={24} lg={24}>
            <Card
              bordered={false}
              title={[<h3 key={0}>Dropout Lead Details</h3>]}
              className={`header-solid mb-10`}

            >
              <Descriptions>
                <Descriptions.Item label="Full Name" span={1}>
                  {this.state.name}
                </Descriptions.Item>
                <Descriptions.Item label="Mobile" span={1}>
                  {this.state.mobile}
                </Descriptions.Item>
                <Descriptions.Item label="Email" span={1}>
                  {this.state.email}
                </Descriptions.Item>
                <Descriptions.Item label="Project" span={1}>
                  {this.state.project}
                </Descriptions.Item>
                <Descriptions.Item label="Source" span={1}>
                  {this.state.source}
                </Descriptions.Item>
                <Descriptions.Item label="Created By" span={1}>
                  {this.state.createdby}
                </Descriptions.Item>
                <Descriptions.Item label="Dropout Date" span={1}>
                  {this.state.dropoutdate}
                </Descriptions.Item>
                <Descriptions.Item label="Dropout Days" span={1}>
                  {this.state.dropoutdays}
                </Descriptions.Item>
                <Descriptions.Item label="Executive" span={1}>
                  {this.state.dropoutexecutive}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          <Col span={24} md={24} lg={24}>
            <Card
              bordered={false}
              title={[<h3 key={0}>History</h3>]}
              className={`header-solid mb-24`}

            >
              <Timeline>
                {Object.keys(this.state.data).map(key => {
                  console.log(this.state.data[key].comments);
                  return (
                    <Timeline.Item color="green" key={this.state.data[key].key}>
                      <Col span={24} md={24} lg={24} className={`header-solid mb-24`}>
                        <Card bordered={false} className="card-billing-info">
                          <Descriptions>
                            <Descriptions.Item label="Status" span={1}>
                              {this.state.data[key].completed_status ? this.state.data[key].completed_status + " - " : ""}
                              {this.state.data[key].future_status ? this.state.data[key].future_status : ""}
                            </Descriptions.Item>
                            <Descriptions.Item label="Executive" span={1}>
                              {this.state.data[key].createdby ? this.state.data[key].createdby : "User"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Action Date" span={1}>
                              {this.state.data[key].createddate}
                            </Descriptions.Item>
                            <Descriptions.Item label="Comments" span={3}>
                              {this.state.data[key].comments}
                            </Descriptions.Item>
                          </Descriptions>
                        </Card>
                      </Col>
                    </Timeline.Item>
                  )
                })}
              </Timeline>
            </Card>
          </Col>

        </Row>
      </>
    );
  }
}
