import React, { Component } from "react";
import userServices from "../../api/services/user.services";
import { Steps, Card, Form, Input, Row, Col, Button, Select, message } from "antd";
const { Step } = Steps;

export default class NewUsers extends Component {
  constructor(props) {
    super(props);

    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeDateOfBirth = this.onChangeDateOfBirth.bind(this);
    this.onChangeGender = this.onChangeGender.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeUserName = this.onChangeUserName.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangePasswordConfirmation = this.onChangePasswordConfirmation.bind(this);
    this.onChangeUserDescription = this.onChangeUserDescription.bind(this);
    this.onChangeUserStartDate = this.onChangeUserStartDate.bind(this);
    this.onChangeUserEndDate = this.onChangeUserEndDate.bind(this);
    this.onChangeUserPrimaryEmailId = this.onChangeUserPrimaryEmailId.bind(this);
    this.onChangeUserPrimaryPhoneNumber = this.onChangeUserPrimaryPhoneNumber.bind(this);
    this.onChangeUserSecondaryEmailId = this.onChangeUserSecondaryEmailId.bind(this);
    this.onChangeUserSecondaryPhoneNumber = this.onChangeUserSecondaryPhoneNumber.bind(this);
    this.onChangeAddress1 = this.onChangeAddress1.bind(this);
    this.onChangeAddress2 = this.onChangeAddress2.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onChangeState = this.onChangeState.bind(this);
    this.onChangeZipcode = this.onChangeZipcode.bind(this);

    this.saveUser = this.saveUser.bind(this);
    this.newUser = this.newUser.bind(this);
    this.resetError = this.resetError.bind(this);

    this.state = {
      current: 0,
      "Name": "",
      "DateOfBirth": "",
      "Gender": "",
      "Status": "",
      "UserName": "",
      "Password": "",
      "PasswordConfirmation": "",
      "UserDescription": "",
      "UserStartDate": "",
      "UserEndDate": "",
      "UserPrimaryEmailId": "",
      "UserPrimaryPhoneNumber": "",
      "UserSecondaryEmailId": "",
      "UserSecondaryPhoneNumber": "",
      "Address1": "",
      "Address2": "",
      "City": "",
      "State": "",
      "Zipcode": "",
      "NameError": "",
      "DateOfBirthError": "",
      "GenderError": "",
      "StatusError": "",
      "UserNameError": "",
      "PasswordError": "",
      "PasswordConfirmationError": "",
      "UserDescriptionError": "",
      "UserStartDateError": "",
      "UserEndDateError": "",
      "UserPrimaryEmailIdError": "",
      "UserPrimaryPhoneNumberError": "",
      "UserSecondaryEmailIdError": "",
      "UserSecondaryPhoneNumberError": "",
      "Address1Error": "",
      "Address2Error": "",
      "CityError": "",
      "StateError": "",
      "ZipcodeError": "",
    };
  }

  handleClick = () => {
    this.props.history.push('/leads/leadslist');
  }

  saveUser(e) {
    this.resetError();
    var data = {
      "Name": this.state.Name,
      "DateOfBirth": this.state.DateOfBirth,
      "Gender": this.state.Gender,
      "Status": this.state.Status,
      "UserName": this.state.UserName,
      "Password": this.state.Password,
      "PasswordConfirmation": this.state.PasswordConfirmation,
      "UserType": "SalesExecutive",
      "UserDescription": this.state.UserDescription,
      "UserStartDate": this.state.UserStartDate,
      "UserEndDate": this.state.UserEndDate,
      "UserPrimaryEmailId": this.state.UserPrimaryEmailId,
      "UserPrimaryPhoneNumber": this.state.UserPrimaryPhoneNumber,
      "UserSecondaryEmailId": this.state.UserSecondaryEmailId,
      "UserSecondaryPhoneNumber": this.state.UserSecondaryPhoneNumber,
      "Address1": this.state.Address1,
      "Address2": this.state.Address2,
      "City": this.state.City,
      "State": this.state.State,
      "Zipcode": this.state.Zipcode
    };

    userServices.addUsers(data)
      .then(response => {
        console.log(response.data);
        message.success("User added successfully");
        this.handleClick();
      })
      .catch(e => {
        console.log(e.response.data.data);
        const errorData = e.response.data.data;
        const keys = Object.keys(errorData);
        keys.map(key => {
          if (key === 'Name') {
            this.setState({
              NameError: errorData[key]
            })
          }
          if (key === 'Gender') {
            this.setState({
              GenderError: errorData[key]
            })
          }
          if (key === 'DateOfBirth') {
            this.setState({
              DateOfBirthError: errorData[key]
            })
          }
          if (key === 'Status') {
            this.setState({
              StatusError: errorData[key]
            })
          }
          if (key === 'UserName') {
            this.setState({
              UserNameError: errorData[key]
            })
          }
          if (key === 'Password') {
            this.setState({
              PasswordError: errorData[key]
            })
          }
          if (key === 'PasswordConfirmation') {
            this.setState({
              PasswordConfirmationError: errorData[key]
            })
          }
          if (key === 'UserPrimaryPhoneNumber') {
            this.setState({
              UserPrimaryPhoneNumberError: errorData[key]
            })
          }
          if (key === 'UserSecondaryPhoneNumber') {
            this.setState({
              UserSecondaryPhoneNumberError: errorData[key]
            })
          }
          if (key === 'UserPrimaryEmailId') {
            this.setState({
              UserPrimaryEmailIdError: errorData[key]
            })
          }
          if (key === 'UserSecondaryEmailId') {
            this.setState({
              UserSecondaryEmailIdError: errorData[key]
            })
          }
          if (key === 'UserStartDate') {
            this.setState({
              UserStartDateError: errorData[key]
            })
          }
          if (key === 'UserEndDate') {
            this.setState({
              UserEndDateError: errorData[key]
            })
          }
          if (key === 'Address1') {
            this.setState({
              Address1Error: errorData[key]
            })
          }
          if (key === 'Address2') {
            this.setState({
              Address2Error: errorData[key]
            })
          }
          if (key === 'City') {
            this.setState({
              CityError: errorData[key]
            })
          }
          if (key === 'State') {
            this.setState({
              StateError: errorData[key]
            })
          }
          if (key === 'Zipcode') {
            this.setState({
              ZipcodeError: errorData[key]
            })
          }
          if (key === 'UserDescription') {
            this.setState({
              UserDescriptionError: errorData[key]
            })
          }
        })
        if (e.response.data.code == 422) {
          message.error("Please fill all the required fields")
        } else {
          message.error("Error");
        }

      });
  }

  newUser(e) {
    this.setState({
      "Name": "",
      "DateOfBirth": "",
      "Gender": "",
      "UserName": "",
      "Password": "",
      "PasswordConfirmation": "",
      "UserDescription": "",
      "UserStartDate": "",
      "UserEndDate": "",
      "UserPrimaryEmailId": "",
      "UserPrimaryPhoneNumber": "",
      "UserSecondaryEmailId": "",
      "UserSecondaryPhoneNumber": "",
      "Address1": "",
      "Address2": "",
      "City": "",
      "State": "",
      "Zipcode": ""
    });
  }

  resetError() {
    this.setState({
      "NameError": "",
      "DateOfBirthError": "",
      "GenderError": "",
      "StatusError": "",
      "UserNameError": "",
      "PasswordError": "",
      "PasswordConfirmationError": "",
      "UserDescriptionError": "",
      "UserStartDateError": "",
      "UserEndDateError": "",
      "UserPrimaryEmailIdError": "",
      "UserPrimaryPhoneNumberError": "",
      "UserSecondaryEmailIdError": "",
      "UserSecondaryPhoneNumberError": "",
      "Address1Error": "",
      "Address2Error": "",
      "CityError": "",
      "StateError": "",
      "ZipcodeError": "",
    })
  }

  onChangeName(e) {
    this.setState({
      Name: e.target.value
    });
  }
  onChangeDateOfBirth(e) {
    this.setState({
      DateOfBirth: e.target.value
    });
  }
  onChangeGender(e) {
    this.setState({
      Gender: e
    });
  }
  onChangeStatus(e) {
    this.setState({
      Status: e
    });
  }
  onChangeUserName(e) {
    this.setState({
      UserName: e.target.value
    });
  }
  onChangePassword(e) {
    this.setState({
      Password: e.target.value
    });
  }
  onChangePasswordConfirmation(e) {
    this.setState({
      PasswordConfirmation: e.target.value
    });
  }
  onChangeUserDescription(e) {
    this.setState({
      UserDescription: e.target.value
    });
  }
  onChangeUserStartDate(e) {
    this.setState({
      UserStartDate: e.target.value
    });
  }
  onChangeUserEndDate(e) {
    this.setState({
      UserEndDate: e.target.value
    });
  }
  onChangeUserPrimaryEmailId(e) {
    this.setState({
      UserPrimaryEmailId: e.target.value
    });
  }
  onChangeUserPrimaryPhoneNumber(e) {
    this.setState({
      UserPrimaryPhoneNumber: e.target.value
    });
  }
  onChangeUserSecondaryEmailId(e) {
    this.setState({
      UserSecondaryEmailId: e.target.value
    });
  }
  onChangeUserSecondaryPhoneNumber(e) {
    this.setState({
      UserSecondaryPhoneNumber: e.target.value
    });
  }
  onChangeAddress1(e) {
    this.setState({
      Address1: e.target.value
    });
  }
  onChangeAddress2(e) {
    this.setState({
      Address2: e.target.value
    });
  }
  onChangeCity(e) {
    this.setState({
      City: e.target.value
    });
  }
  onChangeState(e) {
    this.setState({
      State: e
    });
  }
  onChangeZipcode(e) {
    this.setState({
      Zipcode: e.target.value
    });
  }

  onChange = (current) => {
    console.log("onChange:", current);
    this.setState({ current });
  };

  next = () => {
    this.setState({
      current: this.state.current + 1,
    });
  };

  prev = () => {
    this.setState({
      current: this.state.current - 1,
    });
  };
  render() {
    const { current } = this.state;
    return (
      <>
        <div className="mx-auto mt-10" style={{ maxWidth: "800px" }}>
          <div className="mb-24">
            <Card
              className="header-solid"
              bordered={false}
              title={[
                <React.Fragment key={0}>
                  <h5 className="mb-0">Executive</h5>
                </React.Fragment>,
              ]}
            >
              <Form layout="vertical">
                <Row gutter={[24, 0]}>
                  <Col span={16}>
                    <Form.Item
                      colon={false}
                      className="Name"
                      label="Full Name"
                      name="Name"
                      value={this.state.Name}
                      onChange={this.onChangeName}
                      rules={[
                        {
                          required: true,
                          message: "Please input the Name!",
                        },
                      ]}
                    >
                      <Input placeholder="eg. Michael" />
                      {this.state.NameError && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.NameError}</span>}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Gender" colon={false} required>
                      <Select
                        size="large"
                        defaultValue="Select Gender"
                        onChange={this.onChangeGender}
                        rules={[
                          {
                            required: true,
                            message: "Please select Gender!",
                          },
                        ]}
                      >
                        <Select.Option value="Male">Male</Select.Option>
                        <Select.Option value="Female">Female</Select.Option>
                      </Select>
                      {this.state.GenderError && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.GenderError}</span>}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      colon={false}
                      className="DateOfBirth"
                      label="Date of Birth"
                      name="DateOfBirth"
                      value={this.state.DateOfBirth}
                      onChange={this.onChangeDateOfBirth}
                      rules={[
                        {
                          required: true,
                          message: "Please input the DOB!",
                        },
                      ]}
                    >
                      <Input placeholder="dd-mm-yyyy" type="date" />
                      {this.state.DateOfBirthError && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.DateOfBirthError}</span>}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Status" colon={false} required>
                      <Select
                        size="large"
                        defaultValue="Select Status"
                        onChange={this.onChangeStatus}
                        rules={[
                          {
                            required: true,
                            message: "Please select Status!",
                          },
                        ]}
                      >
                        <Select.Option value="Active">Active</Select.Option>
                        <Select.Option value="In-Active">In-Active</Select.Option>
                      </Select>
                      {this.state.StatusError && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.StatusError}</span>}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      colon={false}
                      className="Username"
                      label="Username"
                      name="Username"
                      value={this.state.UserName}
                      onChange={this.onChangeUserName}
                      rules={[
                        {
                          required: true,
                          message: "Please input the Username!",
                        },
                      ]}
                    >
                      <Input placeholder="eg. Username" />
                      {this.state.UserNameError && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.UserNameError}</span>}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      colon={false}
                      className="Password"
                      label="Password"
                      name="Password"
                      value={this.state.Password}
                      onChange={this.onChangePassword}
                      rules={[
                        {
                          required: true,
                          message: "Please input the Password!",
                        },
                      ]}
                    >
                      <Input placeholder="*****" type="password" />
                      {this.state.PasswordError && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.PasswordError}</span>}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      colon={false}
                      className="RepeatPassword"
                      label="Repeat Password"
                      name="RepeatPassword"
                      value={this.state.PasswordConfirmation}
                      onChange={this.onChangePasswordConfirmation}
                      rules={[
                        {
                          required: true,
                          message: "Please input the Repeat Password!",
                        },
                      ]}
                    >
                      <Input placeholder="*****" type="password" />
                      {this.state.PasswordConfirmationError && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.PasswordConfirmationError}</span>}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      colon={false}
                      className="UserPrimaryPhoneNumber"
                      label="Primary Phone Number"
                      name="UserPrimaryPhoneNumber"
                      value={this.state.UserPrimaryPhoneNumber}
                      onChange={this.onChangeUserPrimaryPhoneNumber}
                      rules={[
                        {
                          required: true,
                          message: "Please input the Primary Phone Number!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="eg. 9999999999"
                        type="text"
                      />
                      {this.state.UserPrimaryPhoneNumberError && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.UserPrimaryPhoneNumberError}</span>}
                    </Form.Item>
                  </Col><Col span={12}>
                    <Form.Item
                      colon={false}
                      className="UserSecondaryPhoneNumber"
                      label="Secondary Phone Number"
                      name="UserSecondaryPhoneNumber"
                      value={this.state.UserSecondaryPhoneNumber}
                      onChange={this.onChangeUserSecondaryPhoneNumber}
                      rules={[
                        {
                          required: false,
                          message: "Please input the Secondary Phone Number!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="eg. 9999999999"
                        type="text"
                      />
                      {this.state.UserSecondaryPhoneNumberError && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.UserSecondaryPhoneNumberError}</span>}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      colon={false}
                      className="UserPrimaryEmailId"
                      label="Primary Email Address"
                      name="UserPrimaryEmailId"
                      value={this.state.UserPrimaryEmailId}
                      onChange={this.onChangeUserPrimaryEmailId}
                      rules={[
                        {
                          required: false,
                          message: "Please input the valid Primary Email Address!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="eg. soft@dashboard.com"
                        type="email"
                      />
                      {this.state.UserPrimaryEmailIdError && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.UserPrimaryEmailIdError}</span>}
                    </Form.Item>
                  </Col><Col span={12}>
                    <Form.Item
                      colon={false}
                      className="UserSecondaryEmailId"
                      label="Secondary Email Address"
                      name="UserSecondaryEmailId"
                      value={this.state.UserSecondaryEmailId}
                      onChange={this.onChangeUserSecondaryEmailId}
                      rules={[
                        {
                          required: false,
                          message: "Please input the valid Secondary Email Address!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="eg. soft@dashboard.com"
                        type="email"
                      />
                      {this.state.UserSecondaryEmailIdError && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.UserSecondaryEmailIdError}</span>}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      colon={false}
                      className="UserStartDate"
                      label="Start Date"
                      name="UserStartDate"
                      value={this.state.UserStartDate}
                      onChange={this.onChangeUserStartDate}
                      rules={[
                        {
                          required: true,
                          message: "Please input the Start Date!",
                        },
                      ]}
                    >
                      <Input placeholder="" type="date" />
                      {this.state.UserStartDateError && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.UserStartDateError}</span>}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      colon={false}
                      className="UserEndDate"
                      label="End Date"
                      name="UserEndDate"
                      value={this.state.UserEndDate}
                      onChange={this.onChangeUserEndDate}
                      rules={[
                        {
                          required: true,
                          message: "Please input the End Date!",
                        },
                      ]}
                    >
                      <Input placeholder="" type="date" />
                      {this.state.UserEndDateError && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.UserEndDateError}</span>}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      colon={false}
                      className="Address1"
                      label="Address 1"
                      name="Address1"
                      value={this.state.Address1}
                      onChange={this.onChangeAddress1}
                      rules={[
                        {
                          required: true,
                          message: "Please input the Address 1!",
                        },
                      ]}
                    >
                      <Input placeholder="eg. Street 111" />
                      {this.state.Address1Error && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.Address1Error}</span>}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      colon={false}
                      className="Address2"
                      label="Address 2"
                      name="Address2"
                      value={this.state.Address2}
                      onChange={this.onChangeAddress2}
                      rules={[
                        {
                          required: false,
                          message: "Please input the valid Address 2!",
                        },
                      ]}
                    >
                      <Input placeholder="eg. Street 221" />
                      {this.state.Address2Error && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.Address2Error}</span>}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      colon={false}
                      className="City"
                      label="City"
                      name="City"
                      value={this.state.City}
                      onChange={this.onChangeCity}
                      rules={[
                        {
                          required: true,
                          message: "Please input the City!",
                        },
                      ]}
                    >
                      <Input placeholder="eg. Hyderabad" />
                      {this.state.CityError && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.CityError}</span>}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="State" colon={false} required>
                      <Select
                        size="large"
                        defaultValue="Select State"
                        onChange={this.onChangeState}
                        rules={[
                          {
                            required: true,
                            message: "Please select State!",
                          },
                        ]}
                      >
                        <Select.Option value="TS">Telengana</Select.Option>
                      </Select>
                      {this.state.StateError && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.StateError}</span>}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      colon={false}
                      className="Zipcode"
                      label="Zipcode"
                      name="Zipcode"
                      value={this.state.Zipcode}
                      onChange={this.onChangeZipcode}
                      rules={[
                        {
                          required: true,
                          message: "Please input the Zipcode!",
                        },
                      ]}
                    >
                      <Input placeholder="5 letters" type="text" />
                      {this.state.ZipcodeError && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.ZipcodeError}</span>}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      colon={false}
                      className="Description"
                      label="Description"
                      name="Description"
                      value={this.state.UserDescription}
                      onChange={this.onChangeUserDescription}
                      rules={[
                        {
                          required: false,
                          message: "Please input the valid Description!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="eg. Enter Description"
                        type="text"
                      />
                      {this.state.UserDescriptionError && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.UserDescriptionError}</span>}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Button onClick={this.newUser} className="px-25">
                      RESET
                    </Button>
                  </Col>

                  <Col span={12} className="text-right">
                    <Button
                      type="primary"
                      className="px-25"
                      onClick={this.saveUser}
                    >
                      SUBMIT
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </div>
        </div>
      </>
    );
  }
}
