/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
import { React, Component, useState } from "react";
import LoginAPI from "../../../api/services/login.services";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Checkbox,
  Alert,
  message,
} from "antd";
import signinbg from "../../images/info-rocket-ill.png";
import { withRouter } from "react-router-dom";

const { Title } = Typography;
const { Header, Content } = Layout;

class Illustration extends Component {
  constructor(props) {
    super(props);

    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.saveLogin = this.saveLogin.bind(this);
    this.newLogin = this.newLogin.bind(this);

    this.state = {
      token: null,
      username: "",
      password: "",
      usernameerror: "",
      passworderror: "",
      submitted: false
    };
  }

  handleClick = () => {
    this.props.history.push('/dashboard');
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  async saveLogin() {
    this.resetnewLogin();
    var data = {
      username: this.state.username,
      password: this.state.password
    };

    LoginAPI.checklogin(data)
      .then(response => {
        this.setState({
          token: response.data.data.token,
          submitted: true
        });
        window.localStorage.setItem('Authorization', response.data.data.token);
        console.log(response);
        message.success("Login Successful");

        this.handleClick();
      })
      .catch(e => {
        console.log("Error data" + e);
        const errorData = e.response.data.data;
        const keys = Object.keys(errorData);
        keys.map(key => {
          if (key === 'username') {
            this.setState({
              usernameerror: errorData[key]
            })
          }
          if (key === 'password') {
            this.setState({
              passworderror: errorData[key]
            })
          }
        })
        if (e.response.data.code == 422) {
          message.error("Please fill all the required fields")
        } else {
          message.error("Error");
        }
      });
  }

  newLogin() {
    this.setState({
      token: "",
      username: "",
      password: "",
      submitted: false
    });
  }

  resetnewLogin() {
    this.setState({
      usernameerror: "",
      passworderror: "",
    });
  }

  componentDidMount() {
    console.log("illl....");
  }

  render() {
    const onFinish = (values) => {
      console.log("Success:", values);
    };

    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };

    return (
      <>
        <>
          <Layout className="layout-default layout-sign-up-cover layout-sign-up-illustration bg-white">
            <Content className="sign-in ">
              <Row
                gutter={[24, 0]}
                className="row-main ant-row-flex ant-row-flex-space-around ant-row-flex-middle"
              >
                <Col
                  className="col-form mr-auto"
                  xs={{ span: 24, offset: 0 }}
                  lg={{ span: 6, offset: 2 }}
                  md={{ span: 12 }}
                >
                  <Title className="mb-15">Sign In</Title>
                  <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    className="row-col"
                  >
                    <Form.Item
                      className="email"
                      label="Username"
                      name="email"
                      value={this.state.username}
                      onChange={this.onChangeUsername}
                      rules={[
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                      ]}
                    >
                      <Input placeholder="Username" required />
                      {this.state.usernameerror && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.usernameerror}</span>}
                    </Form.Item>
                    <Form.Item
                      className="password"
                      label="Password"
                      name="password"
                      value={this.state.password}
                      onChange={this.onChangePassword}
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input type="password" placeholder="Password" required />
                      {this.state.passworderror && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.passworderror}</span>}
                    </Form.Item>

                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={this.saveLogin}
                        style={{ width: "100%" }}
                      >
                        SIGN IN
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
                <Col
                  className="col-img12 text-center"
                  style={{ padding: 12 }}
                  xs={{ span: 24 }}
                  lg={{ span: 12 }}
                  md={{ span: 12 }}
                >
                  <div>
                    <div className="img">
                      <img src={signinbg} alt="" className="inline-block" />
                    </div>
                    <h4 className="text-white h4">Your journey starts here</h4>
                    <p className="text-white">
                      Just as it takes a company to sustain a product, it takes
                      a community to sustain a protocol.
                    </p>
                  </div>
                </Col>
              </Row>
            </Content>
          </Layout>
        </>
      </>
    );
  }
}

export default withRouter(Illustration);
