import http from "../http-common";
import authHeader from '../auth-header';

class LoginService {
    checklogin(data) {
        return http.post("/builderlogin", data);
    }

    logout() {
        return http.get("/builderlogout", { headers: authHeader() });
    }
}

export default new LoginService();