/* eslint-disable import/no-anonymous-default-export */
import React, { Component } from "react";
import { Row, Col, Table, Card, Select, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const { Option } = Select;

const suffix = (
  <SearchOutlined
    style={{
      fontSize: 16,
      color: "#1890ff",
    }}
  />
);

const data = [

];

export default class extends Component {
  state = {
    filteredInfo: null,
    sortedInfo: null,
    totalPage: 5,
    totalPage1: 5,
    filteredData: [

    ],
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      totalPage: pagination,
    });
  };

  handleFilter = (e) => {
    let newFiltered = [...this.state.filteredData];
    newFiltered = newFiltered.filter(
      (data) =>
        data.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.mobile.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.source.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.project.toString().includes(e.target.value.toLowerCase()) ||
        data.created.toString().includes(e.target.value.toLowerCase()) ||
        data.createdby.toString().includes(e.target.value.toLowerCase())
    );
    this.setState({ filteredData: newFiltered });
  };

  handleTotalPageChange = (val) => {
    this.setState({ totalPage: val });
  };
  handleTotalPageChange1 = (val) => {
    this.setState({ totalPage1: val });
  };
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",

        sorter: (a, b) => a.name.length - b.name.length,
        sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,

        ellipsis: true,
      },
      {
        title: "Age",
        dataIndex: "project",
        key: "project",
        sorter: (a, b) => a.project - b.project,
        sortOrder: sortedInfo.columnKey === "project" && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",

        sorter: (a, b) => a.address.length - b.address.length,
        sortOrder: sortedInfo.columnKey === "address" && sortedInfo.order,

        ellipsis: true,
      },
    ];

    //  search

    const columns1 = [
      {
        title: "Customer Name",
        dataIndex: "name",
        sorter: (a, b) => a.name.length - b.name.length,
        sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,

        ellipsis: true,
      },
      {
        title: "Mobile",
        dataIndex: "mobile",

        sorter: (a, b) => a.mobile.length - b.mobile.length,
        sortOrder: sortedInfo.columnKey === "mobile" && sortedInfo.order,

        ellipsis: true,
      },
      {
        title: "Project",
        dataIndex: "project",
        sorter: (a, b) => a.project.length - b.project.length,
        sortOrder: sortedInfo.columnKey === "project" && sortedInfo.order,

        ellipsis: true,
      },
      {
        title: "Description",
        dataIndex: "created",
        sorter: (a, b) => a.created.length - b.created.length,
        sortOrder: sortedInfo.columnKey === "created" && sortedInfo.order,

        ellipsis: true,
      },
      {
        title: "Requested On",
        dataIndex: "createdby",
        sorter: (a, b) => a.createdby.length - b.createdby.length,
        sortOrder: sortedInfo.columnKey === "createdby" && sortedInfo.order,

        ellipsis: true,
      },
      {
        title: "Executive",
        dataIndex: "createdby",
        sorter: (a, b) => a.createdby.length - b.createdby.length,
        sortOrder: sortedInfo.columnKey === "createdby" && sortedInfo.order,

        ellipsis: true,
      },
      {
        title: "Actions",
        dataIndex: "action",
        sorter: false,

        ellipsis: true,
      },
    ];

    return (
      <>
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Card
              bodyStyle={{ padding: "16px 0px 0px" }}
              bordered={false}
              className="header-solid mb-24"
              title={[<h5 className="font-semibold"> Internal Transfer</h5>]}
            >
              <div className="px-25">
                <Row gutter={[24, 0]} className="mb-24">
                  <Col span={24} lg={12}>
                    <Select
                      defaultValue="5"
                      onChange={this.handleTotalPageChange1}
                      size="large"
                    >
                      <Option value="5">5</Option>
                      <Option value="10">10</Option>
                      <Option value="15">15</Option>
                      <Option value="20">20</Option>
                      <Option value="25">25</Option>
                    </Select>

                    <label className="ml-10">entries per page</label>
                  </Col>
                  <Col span={24} lg={12} className="text-right">
                    <Input
                      style={{ maxWidth: "200px", borderRadius: "8px" }}
                      placeholder="input search text"
                      size="small"
                      suffix={suffix}
                      onChange={this.handleFilter}
                    />
                  </Col>
                </Row>
              </div>
              <Table
                className="mt-20"
                columns={columns1}
                dataSource={this.state.filteredData}
                tableLayout="auto"
                onChange={onchange}
                scroll={{ x: 500 }}
                pagination={{
                  pageSize: this.state.totalPage1,
                }}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
