/* eslint-disable import/no-anonymous-default-export */
import React, { Component } from "react";
import { Row, Col, Table, Card, Select, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import userServices from "../../api/services/user.services";

const { Option } = Select;

const suffix = (
  <SearchOutlined
    style={{
      fontSize: 16,
      color: "#1890ff",
    }}
  />
);

const data = [

];

export default class extends Component {
  constructor(props) {
    super(props);

    this.onClickView = this.onClickView.bind(this);
  }
  componentDidMount() {
    this.getunAssignedProjectsList();
  }
  getunAssignedProjectsList() {
    userServices.getUsers()
      .then(response => {
        this.data = response.data.data;
        this.setState({
          filteredData: response.data.data
        });
        console.log("Data map");
        console.log(this.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  onClickView(e) {
    //alert(e);
    this.props.history.push({
      pathname: '/user/view',
      search: `?user=` + btoa(e)
    });
  }

  state = {
    filteredInfo: null,
    sortedInfo: null,
    totalPage: 5,
    totalPage1: 5,
    filteredData: [

    ],
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      totalPage: pagination,
    });
  };

  handleFilter = (e) => {
    let newFiltered = [...this.state.filteredData];
    newFiltered = newFiltered.filter(
      (data) =>
        data.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.mobile.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.source.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.project.toString().includes(e.target.value.toLowerCase()) ||
        data.created.toString().includes(e.target.value.toLowerCase()) ||
        data.createdby.toString().includes(e.target.value.toLowerCase())
    );
    this.setState({ filteredData: newFiltered });
  };

  handleTotalPageChange = (val) => {
    this.setState({ totalPage: val });
  };
  handleTotalPageChange1 = (val) => {
    this.setState({ totalPage1: val });
  };
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    //  search

    const columns1 = [
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => a.name.length - b.name.length,
        sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,

        ellipsis: true,
      },
      {
        title: "Username",
        dataIndex: "username",

        sorter: (a, b) => a.username.length - b.username.length,
        sortOrder: sortedInfo.columnKey === "username" && sortedInfo.order,

        ellipsis: true,
      },
      {
        title: "Role",
        dataIndex: "role",
        sorter: (a, b) => a.role.length - b.role.length,
        sortOrder: sortedInfo.columnKey === "role" && sortedInfo.order,

        ellipsis: true,
      },
      {
        title: "Email",
        dataIndex: "email",
        sorter: (a, b) => a.email.length - b.email.length,
        sortOrder: sortedInfo.columnKey === "email" && sortedInfo.order,

        ellipsis: true,
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        sorter: (a, b) => a.mobile.length - b.mobile.length,
        sortOrder: sortedInfo.columnKey === "mobile" && sortedInfo.order,

        ellipsis: true,
      },
      {
        title: "Created Date",
        dataIndex: "created",
        sorter: (a, b) => a.created.length - b.created.length,
        sortOrder: sortedInfo.columnKey === "created" && sortedInfo.order,

        ellipsis: true,
      },
      {
        title: "Actions",
        dataIndex: "action",
        sorter: false,
        render: (text, record) => (
          <span>
            <Button type="link" onClick={() => this.onClickView(record.key)}>View</Button>
          </span>
        ),
        ellipsis: true,
      },
    ];

    return (
      <>
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Card
              bodyStyle={{ padding: "16px 0px 0px" }}
              bordered={false}
              className="header-solid mb-24"
              title={[<h5 className="font-semibold">Users List</h5>]}
            >
              <div className="px-25">
                <Row gutter={[24, 0]} className="mb-24">
                  <Col span={24} lg={12}>
                    <Select
                      defaultValue="5"
                      onChange={this.handleTotalPageChange1}
                      size="large"
                    >
                      <Option value="5">5</Option>
                      <Option value="10">10</Option>
                      <Option value="15">15</Option>
                      <Option value="20">20</Option>
                      <Option value="25">25</Option>
                    </Select>

                    <label className="ml-10">entries per page</label>
                  </Col>
                  <Col span={24} lg={12} className="text-right">
                    <Input
                      style={{ maxWidth: "200px", borderRadius: "8px" }}
                      placeholder="input search text"
                      size="small"
                      suffix={suffix}
                      onChange={this.handleFilter}
                    />
                  </Col>
                </Row>
              </div>
              <Table
                className="mt-20"
                columns={columns1}
                dataSource={this.state.filteredData}
                tableLayout="auto"
                onChange={onchange}
                scroll={{ x: 500 }}
                pagination={{
                  pageSize: this.state.totalPage1,
                }}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
