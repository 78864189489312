import http from "../http-common";
import authHeader from '../auth-header';

class MasterService {
    getSourceType() {
        return http.get("/getsourcetype", { headers: authHeader() });
    }

    getSource(id) {
        return http.get("/getsource/" + id, { headers: authHeader() });
    }
}

export default new MasterService();