import React, { Component } from "react";
import {
    Tabs,
    Card,
    Row,
    Col,
    Anchor,
    Avatar,
    Switch,
    Form,
    Input,
    Button,
    Select,
    Affix,
    Descriptions,
    Modal,
    Table
} from "antd";
import {
    AppstoreFilled,
    SnippetsFilled,
    DeleteFilled,
    UnlockFilled,
    ExclamationCircleFilled,
    CheckCircleOutlined
} from "@ant-design/icons";
import profilavatar from "../images/default-user.png";
import userServices from "../../api/services/user.services";

const { TabPane } = Tabs;
const { confirm } = Modal;

function onChange(checked) {
    console.log(`switch to ${checked}`);
}
function callback(key) {
    console.log(key);
}

function onBlur() {
    console.log("blur");
}

function onFocus() {
    console.log("focus");
}

const { Link } = Anchor;
const { Option } = Select;

function handleChange(value) {
    console.log(`selected ${value}`);
}

export default class TimeLine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            gender: "",
            dob: "",
            status: "",
            username: "",
            usertype: "",
            primaryphone: "",
            secondaryphone: "",
            primaryemail: "",
            secondaryemail: "",
            startdate: "",
            enddate: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zipcode: "",
            description: "",
            encodedid: "",
            projectname: "",
            data: {},
            filteredData: [

            ],
        };

        this.onClickDelete = this.onClickDelete.bind(this);
    }

    componentDidMount() {
        this.getUserDetails();
    }

    onClickDelete(value) {
        confirm({
            title: 'Do you Want to delete the user?',
            icon: <ExclamationCircleFilled />,
            content: "",
            onOk() {
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                    userServices.deleteUser(value).then(response => {
                        Modal.success({
                            content: 'User deleted successfully',
                        });
                    });
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() {
                return false;
            },
        });
        console.log(value);
    }

    getUserDetails() {
        let location = this.props.location;
        let params = new URLSearchParams(location.search);
        let user = params.get('user');
        let userId = atob(user);
        //console.log(userId);

        this.setState({
            encodedid: user
        });

        userServices.getUserDetails(userId)
            .then(response => {
                console.log(response);
                this.setState({
                    name: response.data.data.name
                });
                this.setState({
                    gender: response.data.data.gender
                });
                this.setState({
                    dob: response.data.data.dob
                });
                this.setState({
                    status: response.data.data.status
                });
                this.setState({
                    username: response.data.data.username
                });
                this.setState({
                    usertype: response.data.data.usertype
                });
                this.setState({
                    primaryphone: response.data.data.primaryphone
                });
                this.setState({
                    secondaryphone: response.data.data.secondaryphone
                });
                this.setState({
                    primaryemail: response.data.data.primaryemail
                });
                this.setState({
                    secondaryemail: response.data.data.secondaryemail
                });
                this.setState({
                    startdate: response.data.data.startdate
                });
                this.setState({
                    enddate: response.data.data.enddate
                });
                this.setState({
                    address1: response.data.data.address1
                });
                this.setState({
                    address2: response.data.data.address2
                });
                this.setState({
                    city: response.data.data.city
                });
                this.setState({
                    state: response.data.data.state
                });
                this.setState({
                    zipcode: response.data.data.zipcode
                });
                this.setState({
                    description: response.data.data.description
                });
                this.setState({
                    projectname: response.data.data.projectdata[0].name
                });
                this.setState({
                    filteredData: response.data.data.projectdata
                });
            })
            .catch(e => {
                console.log(e);
            });
    };

    // getLeadsHistory() {
    //     let location = this.props.location;
    //     let params = new URLSearchParams(location.search);
    //     let lead = params.get('lead');
    //     let leadId = atob(lead);
    //     console.log(leadId);
    //     leadsService.getLeadHistory(leadId)
    //         .then(response => {
    //             this.setState({
    //                 data: response.data.data
    //             });
    //             console.log(this.state.data);
    //         })
    //         .catch(e => {
    //             console.log(e);
    //         });
    // }

    render() {
        const timeline = [0, { cls: "" }];
        const columns1 = [
            {
                title: "Project Name",
                dataIndex: "name",
            },
        ];
        return (
            <>
                <Tabs className="tabs-sliding" defaultActiveKey="1" onChange={callback}>
                    <TabPane tab="Profile" key="1">
                        <Row gutter={[24, 24]}>
                            <Col lg={6} span={24}>
                                <Affix offsetTop={10}>
                                    <Card className="header-solid" bordered={false}>
                                        <Anchor className="fixed" affix={false}>
                                            <Link
                                                href="#profile"
                                                title={[
                                                    <div className="ant-anchor-link-menu" key={0}>
                                                        <AppstoreFilled className="text-lg text-gray-6" />
                                                        <h4>Profile</h4>
                                                    </div>,
                                                ]}
                                            />

                                            <Link
                                                href="#basic-info"
                                                title={[
                                                    <div className="ant-anchor-link-menu" key={0}>
                                                        <SnippetsFilled className="text-lg text-gray-6" />
                                                        <h4>Basic Info</h4>
                                                    </div>,
                                                ]}
                                            />
                                            {/* <Link
                                                href="#change-password"
                                                title={[
                                                    <div className="ant-anchor-link-menu" key={0}>
                                                        <UnlockFilled className="text-lg text-gray-6" />
                                                        <h4>Change Password</h4>
                                                    </div>,
                                                ]}
                                            /> */}
                                            <Link
                                                href="#delete-account"
                                                title={[
                                                    <div className="ant-anchor-link-menu" key={0}>
                                                        <DeleteFilled className="text-lg text-gray-6" />
                                                        <h4>Delete Account</h4>
                                                    </div>,
                                                ]}
                                            />
                                        </Anchor>
                                    </Card>
                                </Affix>
                            </Col>

                            <Col span={24} lg={18} id="my-scroll-layout">
                                <Card
                                    id="profile"
                                    className="card-profile-head"
                                    bordered={false}
                                    bodyStyle={{ display: "none" }}
                                    title={
                                        <Row
                                            justify="space-between"
                                            align="middle"
                                            gutter={[24, 0]}
                                        >
                                            <Col span={24} md={12} className="col-info">
                                                <Avatar.Group>
                                                    <Avatar size={74} shape="square" src={profilavatar} />
                                                    <div className="avatar-info">
                                                        <h4 className="font-semibold m-0">{this.state.name}</h4>
                                                        <p>{this.state.usertype}</p>
                                                    </div>
                                                </Avatar.Group>
                                            </Col>
                                            <Col
                                                span={24}
                                                md={12}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <div>
                                                    <h1><b>{this.state.projectname}</b></h1>
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                ></Card>
                                <Card
                                    id="basic-info"
                                    className="mb-24 header-solid"
                                    bordered={false}
                                    title={[
                                        <h5 className="mb-0" key={0}>
                                            Basic Info
                                        </h5>,
                                    ]}
                                >
                                    <Form layout="vertical">
                                        <Row gutter={[24, 0]}>
                                            <Col span={24}>
                                                <Descriptions>
                                                    <Descriptions.Item label="Name" span={1}>
                                                        {this.state.name}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Gender" span={1}>
                                                        {this.state.gender}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Date of Birth" span={1}>
                                                        {this.state.dob}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Username" span={1}>
                                                        {this.state.username}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Primary Phone" span={1}>
                                                        {this.state.primaryphone}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Secondary Phone" span={1}>
                                                        {this.state.secondaryphone}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Primary Email" span={1}>
                                                        {this.state.primaryemail}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Secondary Email" span={1}>
                                                        {this.state.secondaryemail}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Status" span={1}>
                                                        {this.state.status}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Start Date" span={1}>
                                                        {this.state.startdate}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="End Date" span={1}>
                                                        {this.state.enddate}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Address" span={6}>
                                                        {this.state.address1 ? this.state.address1 : ""}
                                                        {this.state.address2 ? " , " + this.state.address2 : ""}
                                                        {this.state.city ? " , " + this.state.city : ""}
                                                        {this.state.state ? " , " + this.state.state : ""}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Description" span={1}>
                                                        {this.state.description}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card>
                                {/* <Card
                                    id="change-password"
                                    className="mb-24 header-solid"
                                    bordered={false}
                                    title={[
                                        <h5 className="mb-0" key={0}>
                                            Change Password
                                        </h5>,
                                    ]}
                                >
                                    <Form layout="vertical" className="ant-password-box">
                                        <Row gutter={[24, 0]}>
                                            <Col span={24}>
                                                <Form.Item label="New password" colon={false}>
                                                    <Input.Password placeholder="New password" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item label="Confirm new password" colon={false}>
                                                    <Input.Password placeholder="Confirm new password" />
                                                </Form.Item>
                                                <h5 className="mb-25 font-semibold">
                                                    Password requirements
                                                </h5>
                                                <p className="para">
                                                    Please follow this guide for a strong password:
                                                </p>
                                                <ul className="pl-25 text-muted">
                                                    <li>One special characters</li>
                                                    <li>Min 6 characters</li>
                                                    <li>One number (2 are recommended)</li>
                                                    <li>Change it often</li>
                                                </ul>
                                            </Col>

                                            <Col span={24} className="text-right">
                                                <Button
                                                    onClick={this.next}
                                                    type="primary"
                                                    className="px-25"
                                                >
                                                    UPDATE PASSWORD
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card> */}
                                <Card
                                    id="delete-account"
                                    className="header-solid mb-24"
                                    bordered={false}
                                    title={[
                                        <React.Fragment key={0}>
                                            <h5 className="font-semibold">Delete Account</h5>
                                            <p className="font-regular">
                                                Once you delete your account, there is no going back.
                                                Please be certain.
                                            </p>
                                        </React.Fragment>,
                                    ]}
                                >
                                    <div className="ant-flex-setting">
                                        <Col
                                            span={24}
                                            className="text-right"
                                            style={{ display: "flex", justifyContent: "flex-end" }}
                                        >
                                            <Button className="me-3">DEACTIVATE</Button>

                                            <Button type="primary" onClick={() => this.onClickDelete(this.state.encodedid)} danger className="px-25">
                                                DELETE ACCOUNT
                                            </Button>
                                        </Col>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="Assigned Projects" key="2">
                        <Card
                            style={{
                                height: "300px",
                                padding: "0px",
                                display: "flex",
                            }}
                        >
                            <Table
                                className="mt-20"
                                columns={columns1}
                                dataSource={this.state.filteredData}
                                tableLayout="auto"
                                scroll={{ x: 500 }}
                            />
                        </Card>
                    </TabPane>
                </Tabs>
            </>
        );
    }
}
