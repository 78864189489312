import React, { Component, useState } from "react";
import { Steps, Card, Form, Input, Row, Col, Button, Select, message } from "antd";
import leadsService from "../../api/services/leads.service";
import masterServices from "../../api/services/master.services";
import projectServices from "../../api/services/project.services";

const { Step } = Steps;
function onChange(checked) {
  console.log(`switch to ${checked}`);
}
function callback(key) {
  console.log(key);
}

function onBlur() {
  console.log("blur");
}

function onFocus() {
  console.log("focus");
}

export default class NewLeads extends Component {

  constructor(props) {
    super(props);

    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeMobile = this.onChangeMobile.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeProject = this.onChangeProject.bind(this);
    this.onChangeSource = this.onChangeSource.bind(this);
    this.onChangeSourceType = this.onChangeSourceType.bind(this);

    this.saveLead = this.saveLead.bind(this);
    this.newLead = this.newLead.bind(this);
    this.resetError = this.resetError.bind(this);

    this.state = {
      name: "",
      email: "",
      mobile: "",
      description: "",
      project: "",
      source: "",
      sourcetype: "",
      nameerror: "",
      emailerror: "",
      mobileerror: "",
      descriptionerror: "",
      projecterror: "",
      sourceerror: "",
      sourcetypeerror: "",
      sourcetypeoptions: [],
      sourceoptions: [],
      projects: [],
    };
  }

  componentDidMount() {
    this.getSourceType();
    this.getProjects();
  }

  async getSourceType() {
    masterServices.getSourceType()
      .then((response) => {
        console.log(response);
        return response.data.data;
      })
      .then((sourcetypeoptions) => {
        this.setState({ sourcetypeoptions });
      })
      .catch(e => {
        console.log(e);
      });
  }

  async getProjects() {
    projectServices.getBuilderProjects()
      .then((response) => {
        console.log(response);
        return response.data.data;
      })
      .then((projects) => {
        this.setState({ projects });
      })
      .catch(e => {
        console.log(e);
      });
  }

  onChangeName(e) {
    this.setState({
      name: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangeMobile(e) {
    this.setState({
      mobile: e.target.value
    });
  }

  onChangeDescription(e) {
    this.setState({
      description: e.target.value
    });
  }

  onChangeProject(e) {
    this.setState({
      project: e
    });
  }

  onChangeSource(e) {
    this.setState({
      source: e
    });
  }

  onChangeSourceType(e) {
    this.setState({
      sourcetype: e
    });
    masterServices.getSource(e)
      .then((response) => {
        console.log(response);
        return response.data.data;
      })
      .then((sourceoptions) => {
        this.setState({ sourceoptions });
      })
      .catch(e => {
        console.log(e);
      });
  }

  handleClick = () => {
    this.props.history.push('/leads/leadslist');
  }

  saveLead() {
    this.resetError();
    var data = {
      name: this.state.name,
      email: this.state.email,
      mobile: this.state.mobile,
      description: this.state.description,
      project: this.state.project,
      source: this.state.source,
      sourcetype: this.state.sourcetype
    };

    leadsService.addLead(data)
      .then(response => {
        console.log(response.data);
        message.success("Lead added successfully");
        this.handleClick();
      })
      .catch(e => {
        console.log(e);
        console.log(e.response.data.data);
        const errorData = e.response.data.data;
        const keys = Object.keys(errorData);
        keys.map(key => {
          if (key === 'sourcetype') {
            this.setState({
              sourcetypeerror: errorData[key]
            })
          }
          if (key === 'source') {
            this.setState({
              sourceerror: errorData[key]
            })
          }
          if (key === 'project') {
            this.setState({
              projecterror: errorData[key]
            })
          }
          if (key === 'name') {
            this.setState({
              nameerror: errorData[key]
            })
          }
          if (key === 'email') {
            this.setState({
              emailerror: errorData[key]
            })
          }
          if (key === 'mobile') {
            this.setState({
              mobileerror: errorData[key]
            })
          }
          if (key === 'description') {
            this.setState({
              descriptionerror: errorData[key]
            })
          }
        })
        if (e.response.data.code == 422) {
          message.error("Please fill all the required fields")
        } else {
          message.error("Error");
        }
      });
  }

  newLead() {
    this.setState({
      name: "",
      email: "",
      mobile: "",
      description: "",
      project: "",
      source: "",
      sourcetype: ""
    });
  }

  resetError() {
    this.setState({
      nameerror: "",
      emailerror: "",
      mobileerror: "",
      descriptionerror: "",
      projecterror: "",
      sourceerror: "",
      sourcetypeerror: ""
    });
  }

  render() {
    return (
      <>
        <div className="mx-auto mt-10" style={{ maxWidth: "800px" }}>
          <div className="mb-24">
            <Card
              className="header-solid"
              bordered={false}
              title={[
                <React.Fragment key={0}>
                  <h5 className="mb-0">Add Lead</h5>
                </React.Fragment>,
              ]}
            >
              <Form layout="vertical">
                <Row gutter={[24, 0]}>
                  <Col span={12}>
                    <Form.Item
                      colon={false}
                      className="sourcetype"
                      label="Source Type"
                      name="sourcetype"
                      rules={[
                        {
                          required: true,
                          message: "Please select source type!",
                        },
                      ]}
                    >
                      <Select
                        defaultValue="Select Source Type"
                        onChange={this.onChangeSourceType}
                        size="large"
                      >
                        {this.state.sourcetypeoptions.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                      {this.state.sourcetypeerror && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.sourcetypeerror}</span>}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      colon={false}
                      className="source"
                      label="Source"
                      name="source"
                      rules={[
                        {
                          required: true,
                          message: "Please select source!",
                        },
                      ]}
                    >
                      <Select
                        defaultValue="Select Source"
                        onChange={this.onChangeSource}
                        size="large"
                      >
                        {this.state.sourceoptions.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                      {this.state.sourceerror && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.sourceerror}</span>}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      className="name"
                      label="Customer Name"
                      name="name"
                      value={this.state.name}
                      onChange={this.onChangeName}
                      rules={[
                        {
                          required: true,
                          message: "Please input the name!",
                        },
                      ]}
                    >
                      <Input placeholder="Customer Name" required />
                      {this.state.nameerror && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.nameerror}</span>}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      colon={false}
                      className="mobile"
                      label="Mobile No"
                      name="mobile"
                      value={this.state.mobile}
                      onChange={this.onChangeMobile}
                      rules={[
                        {
                          required: true,
                          message: "Please input the mobile!",
                        },
                      ]}
                    >
                      <Input placeholder="eg. 9999999999" required />
                      {this.state.mobileerror && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.mobileerror}</span>}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      colon={false}
                      className="email"
                      label="Email Address"
                      name="email"
                      value={this.state.email}
                      onChange={this.onChangeEmail}
                      rules={[
                        {
                          required: false,
                          message: "Please input a valid email!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="eg. soft@dashboard.com"
                        type="email"
                      />
                      {this.state.emailerror && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.emailerror}</span>}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      colon={false}
                      className="project"
                      label="Projects"
                      name="project"
                      rules={[
                        {
                          required: true,
                          message: "Please select project!",
                        },
                      ]}
                    >
                      <Select
                        defaultValue="Select Project"
                        onChange={this.onChangeProject}
                        size="large"
                      >
                        {this.state.projects.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                      {this.state.projecterror && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.projecterror}</span>}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      colon={false}
                      className="description"
                      label="Description"
                      name="description"
                      value={this.state.description}
                      onChange={this.onChangeDescription}
                      rules={[
                        {
                          required: false,
                          message: "Please input valid description!",
                        },
                      ]}
                    >
                      <Input placeholder="eg. Customer description" type="text" />
                      {this.state.descriptionerror && <span className="ant-form-item-explain ant-form-item-explain-error">{this.state.descriptionerror}</span>}
                    </Form.Item>
                  </Col>

                  <Col span={24} className="text-right">
                    <Button
                      onClick={this.saveLead}
                      type="primary"
                      className="px-25"
                    >
                      SUBMIT
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </div>
        </div>
      </>
    );
  }
}
